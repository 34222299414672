<template>
  <router-view/>
</template>

<style>

</style>

<script>
  export default {
    name:"App",
    components:{

    },
  }
</script>
